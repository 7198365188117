import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import DialogscapesLayout from '../components/DialogscapesLayout'
import Content, { HTMLContent } from '../components/Content'

// eslint-disable-next-line
export const DialogscapesTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section>
      {helmet || ''}
      <div className='project--container'>
          <div>
            <PostContent className="test" content={content} />
          </div>
      </div>
    </section>
  )
}

DialogscapesTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const Dialogscapes = ({ data }) => {
  const { markdownRemark: post } = data
  console.log('dialogscapes layout rendered')

  return (
    <DialogscapesLayout>
      <DialogscapesTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate='%s | Dialogscapes'>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name='description'
              content={`${post.html}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </DialogscapesLayout>
  )
}

Dialogscapes.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Dialogscapes

export const pageQuery = graphql`
  query DialogscapesByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`